import { REVISION } from 'three/src/constants.js'

// export { WebGLMultipleRenderTargets } from 'three/src/renderers/WebGLMultipleRenderTargets.js'
export { WebGLMultisampleRenderTarget } from 'three/src/renderers/WebGLMultisampleRenderTarget.js'
// export { WebGLCubeRenderTarget } from 'three/src/renderers/WebGLCubeRenderTarget.js'
export { WebGLRenderTarget } from 'three/src/renderers/WebGLRenderTarget.js'
export { WebGLRenderer } from 'three/src/renderers/WebGLRenderer.js'
export { WebGL1Renderer } from 'three/src/renderers/WebGL1Renderer.js'
// export { ShaderLib } from 'three/src/renderers/shaders/ShaderLib.js'
export { ShaderLib } from './ShaderLib.js'

// export { UniformsLib } from 'three/src/renderers/shaders/UniformsLib.js'
export { UniformsLib } from './UniformsLib.js'

export { UniformsUtils } from 'three/src/renderers/shaders/UniformsUtils.js'
// export { ShaderChunk } from 'three/src/renderers/shaders/ShaderChunk.js'
export { ShaderChunk } from './ShaderChunk.js'

// export { FogExp2 } from 'three/src/scenes/FogExp2.js'
// export { Fog } from 'three/src/scenes/Fog.js'
export { Scene } from 'three/src/scenes/Scene.js'
// export { Sprite } from 'three/src/objects/Sprite.js'
// export { LOD } from 'three/src/objects/LOD.js'
// export { SkinnedMesh } from 'three/src/objects/SkinnedMesh.js'
// export { Skeleton } from 'three/src/objects/Skeleton.js'
// export { Bone } from 'three/src/objects/Bone.js'
export { Mesh } from 'three/src/objects/Mesh.js'
// export { InstancedMesh } from 'three/src/objects/InstancedMesh.js'
// export { LineSegments } from 'three/src/objects/LineSegments.js'
// export { LineLoop } from 'three/src/objects/LineLoop.js'
// export { Line } from 'three/src/objects/Line.js'
// export { Points } from 'three/src/objects/Points.js'
export { Group } from 'three/src/objects/Group.js'
// export { VideoTexture } from 'three/src/textures/VideoTexture.js'
export { DataTexture } from 'three/src/textures/DataTexture.js'
// export { DataTexture2DArray } from 'three/src/textures/DataTexture2DArray.js'
// export { DataTexture3D } from 'three/src/textures/DataTexture3D.js'
// export { CompressedTexture } from 'three/src/textures/CompressedTexture.js'
// export { CubeTexture } from 'three/src/textures/CubeTexture.js'
export { CanvasTexture } from 'three/src/textures/CanvasTexture.js'
// export { DepthTexture } from 'three/src/textures/DepthTexture.js'
export { Texture } from 'three/src/textures/Texture.js'
export * from 'three/src/geometries/Geometries.js'
export * from 'three/src/materials/Materials.js'
// export { AnimationLoader } from 'three/src/loaders/AnimationLoader.js'
// export { CompressedTextureLoader } from 'three/src/loaders/CompressedTextureLoader.js'
// export { CubeTextureLoader } from 'three/src/loaders/CubeTextureLoader.js'
// export { DataTextureLoader } from 'three/src/loaders/DataTextureLoader.js'
export { TextureLoader } from 'three/src/loaders/TextureLoader.js'
// export { ObjectLoader } from 'three/src/loaders/ObjectLoader.js'
// export { MaterialLoader } from 'three/src/loaders/MaterialLoader.js'
// export { BufferGeometryLoader } from 'three/src/loaders/BufferGeometryLoader.js'
export { DefaultLoadingManager, LoadingManager } from 'three/src/loaders/LoadingManager.js'
// export { ImageLoader } from 'three/src/loaders/ImageLoader.js'
export { ImageBitmapLoader } from 'three/src/loaders/ImageBitmapLoader.js'
// export { FontLoader } from 'three/src/loaders/FontLoader.js'
// export { FileLoader } from 'three/src/loaders/FileLoader.js'
// export { Loader } from 'three/src/loaders/Loader.js'
// export { LoaderUtils } from 'three/src/loaders/LoaderUtils.js'
// export { Cache } from 'three/src/loaders/Cache.js'
// export { AudioLoader } from 'three/src/loaders/AudioLoader.js'
// export { SpotLight } from 'three/src/lights/SpotLight.js'
// export { PointLight } from 'three/src/lights/PointLight.js'
// export { RectAreaLight } from 'three/src/lights/RectAreaLight.js'
// export { HemisphereLight } from 'three/src/lights/HemisphereLight.js'
// export { HemisphereLightProbe } from 'three/src/lights/HemisphereLightProbe.js'
// export { DirectionalLight } from 'three/src/lights/DirectionalLight.js'
// export { AmbientLight } from 'three/src/lights/AmbientLight.js'
// export { AmbientLightProbe } from 'three/src/lights/AmbientLightProbe.js'
// export { Light } from 'three/src/lights/Light.js'
// export { LightProbe } from 'three/src/lights/LightProbe.js'
// export { StereoCamera } from 'three/src/cameras/StereoCamera.js'
export { PerspectiveCamera } from 'three/src/cameras/PerspectiveCamera.js'
export { OrthographicCamera } from 'three/src/cameras/OrthographicCamera.js'
// export { CubeCamera } from 'three/src/cameras/CubeCamera.js'
// export { ArrayCamera } from 'three/src/cameras/ArrayCamera.js'
export { Camera } from 'three/src/cameras/Camera.js'
// export { AudioListener } from 'three/src/audio/AudioListener.js'
// export { PositionalAudio } from 'three/src/audio/PositionalAudio.js'
// export { AudioContext } from 'three/src/audio/AudioContext.js'
// export { AudioAnalyser } from 'three/src/audio/AudioAnalyser.js'
// export { Audio } from 'three/src/audio/Audio.js'
// export { VectorKeyframeTrack } from 'three/src/animation/tracks/VectorKeyframeTrack.js'
// export { StringKeyframeTrack } from 'three/src/animation/tracks/StringKeyframeTrack.js'
// export { QuaternionKeyframeTrack } from 'three/src/animation/tracks/QuaternionKeyframeTrack.js'
// export { NumberKeyframeTrack } from 'three/src/animation/tracks/NumberKeyframeTrack.js'
// export { ColorKeyframeTrack } from 'three/src/animation/tracks/ColorKeyframeTrack.js'
// export { BooleanKeyframeTrack } from 'three/src/animation/tracks/BooleanKeyframeTrack.js'
// export { PropertyMixer } from 'three/src/animation/PropertyMixer.js'
// export { PropertyBinding } from 'three/src/animation/PropertyBinding.js'
// export { KeyframeTrack } from 'three/src/animation/KeyframeTrack.js'
// export { AnimationUtils } from 'three/src/animation/AnimationUtils.js'
// export { AnimationObjectGroup } from 'three/src/animation/AnimationObjectGroup.js'
// export { AnimationMixer } from 'three/src/animation/AnimationMixer.js'
// export { AnimationClip } from 'three/src/animation/AnimationClip.js'
export { Uniform } from 'three/src/core/Uniform.js'
export { InstancedBufferGeometry } from 'three/src/core/InstancedBufferGeometry.js'
export { BufferGeometry } from 'three/src/core/BufferGeometry.js'
// export { InterleavedBufferAttribute } from 'three/src/core/InterleavedBufferAttribute.js'
// export { InstancedInterleavedBuffer } from 'three/src/core/InstancedInterleavedBuffer.js'
// export { InterleavedBuffer } from 'three/src/core/InterleavedBuffer.js'
export { InstancedBufferAttribute } from 'three/src/core/InstancedBufferAttribute.js'
export { GLBufferAttribute } from 'three/src/core/GLBufferAttribute.js'
export * from 'three/src/core/BufferAttribute.js'
export { Object3D } from 'three/src/core/Object3D.js'
// export { Raycaster } from 'three/src/core/Raycaster.js'
export { Raycaster } from './Raycaster.js'

export { Layers } from 'three/src/core/Layers.js'
// export { EventDispatcher } from 'three/src/core/EventDispatcher.js'
export { Clock } from 'three/src/core/Clock.js'
// export { QuaternionLinearInterpolant } from 'three/src/math/interpolants/QuaternionLinearInterpolant.js'
// export { LinearInterpolant } from 'three/src/math/interpolants/LinearInterpolant.js'
// export { DiscreteInterpolant } from 'three/src/math/interpolants/DiscreteInterpolant.js'
// export { CubicInterpolant } from 'three/src/math/interpolants/CubicInterpolant.js'
// export { Interpolant } from 'three/src/math/Interpolant.js'
// export { Triangle } from 'three/src/math/Triangle.js'
export * as MathUtils from 'three/src/math/MathUtils.js'
// export { Spherical } from 'three/src/math/Spherical.js'
// export { Cylindrical } from 'three/src/math/Cylindrical.js'
// export { Plane } from 'three/src/math/Plane.js'
// export { Frustum } from 'three/src/math/Frustum.js'
export { Sphere } from 'three/src/math/Sphere.js'
// export { Ray } from 'three/src/math/Ray.js'
export { Matrix4 } from 'three/src/math/Matrix4.js'
export { Matrix3 } from 'three/src/math/Matrix3.js'
export { Box3 } from 'three/src/math/Box3.js'
// export { Box2 } from 'three/src/math/Box2.js'
// export { Line3 } from 'three/src/math/Line3.js'
// export { Euler } from 'three/src/math/Euler.js'
export { Vector4 } from 'three/src/math/Vector4.js'
export { Vector3 } from 'three/src/math/Vector3.js'
export { Vector2 } from 'three/src/math/Vector2.js'
export { Quaternion } from 'three/src/math/Quaternion.js'
export { Color } from 'three/src/math/Color.js'
// export { SphericalHarmonics3 } from 'three/src/math/SphericalHarmonics3.js'
// export { ImmediateRenderObject } from 'three/src/extras/objects/ImmediateRenderObject.js'
// export { SpotLightHelper } from 'three/src/helpers/SpotLightHelper.js'
// export { SkeletonHelper } from 'three/src/helpers/SkeletonHelper.js'
// export { PointLightHelper } from 'three/src/helpers/PointLightHelper.js'
// export { HemisphereLightHelper } from 'three/src/helpers/HemisphereLightHelper.js'
// export { GridHelper } from 'three/src/helpers/GridHelper.js'
// export { PolarGridHelper } from 'three/src/helpers/PolarGridHelper.js'
// export { DirectionalLightHelper } from 'three/src/helpers/DirectionalLightHelper.js'
// export { CameraHelper } from 'three/src/helpers/CameraHelper.js'
// export { BoxHelper } from 'three/src/helpers/BoxHelper.js'
// export { Box3Helper } from 'three/src/helpers/Box3Helper.js'
// export { PlaneHelper } from 'three/src/helpers/PlaneHelper.js'
// export { ArrowHelper } from 'three/src/helpers/ArrowHelper.js'
// export { AxesHelper } from 'three/src/helpers/AxesHelper.js'
// export * from 'three/src/extras/curves/Curves.js'
// export { Shape } from 'three/src/extras/core/Shape.js'
// export { Path } from 'three/src/extras/core/Path.js'
// export { ShapePath } from 'three/src/extras/core/ShapePath.js'
// export { Font } from 'three/src/extras/core/Font.js'
// export { CurvePath } from 'three/src/extras/core/CurvePath.js'
// export { Curve } from 'three/src/extras/core/Curve.js'
// export { DataUtils } from 'three/src/extras/DataUtils.js'
// export { ImageUtils } from 'three/src/extras/ImageUtils.js'
// export { ShapeUtils } from 'three/src/extras/ShapeUtils.js'
// export { PMREMGenerator } from 'three/src/extras/PMREMGenerator.js'
// export { WebGLUtils } from 'three/src/renderers/webgl/WebGLUtils.js'
export * from 'three/src/constants.js'
// export * from 'three/src/Three.Legacy.js'

if (typeof __THREE_DEVTOOLS__ !== 'undefined') {
  /* eslint-disable no-undef */
  __THREE_DEVTOOLS__.dispatchEvent(
    new CustomEvent('register', {
      detail: {
        revision: REVISION,
      },
    }),
  )
  /* eslint-enable no-undef */
}

if (typeof window !== 'undefined') {
  if (window.__THREE__) {
    console.warn('WARNING: Multiple instances of Three.js being imported.')
  } else {
    window.__THREE__ = REVISION
  }
}

import React, { useEffect, useState } from 'react'
import classNames from 'classnames/bind'

import * as s from './Cookiebar.module.css'

const cn = classNames.bind(s)

function Cookiebar() {
  const [hasAnswered, setHasAnswered] = useState(true)
  const [isHideAnimationEnded, setIsHideAnimationEnded] = useState(false)

  useEffect(() => {
    setHasAnswered(window.localStorage.getItem('consentMode') !== null)
  }, [])

  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
  }
  function gtag() {
    window.dataLayer.push(arguments)
  }

  const acceptCookie = () => {
    setHasAnswered(true)

    const consentMode = {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    }
    window.localStorage.setItem('consentMode', JSON.stringify(consentMode))

    gtag('consent', 'update', consentMode)
  }

  const denyCookie = () => {
    setHasAnswered(true)

    const consentMode = {
      ad_storage: 'denied',
      analytics_storage: 'denied',
    }
    window.localStorage.setItem('consentMode', JSON.stringify(consentMode))

    gtag('consent', 'update', consentMode)
  }

  return (
    <div
      className={cn('cookiebar', { hasAnswered, isHideAnimationEnded })}
      onTransitionEnd={() => hasAnswered && setIsHideAnimationEnded(true)}
    >
      <div className={cn('cookiebarInner')}>
        <div className={cn('message')}>Hej! We use analytic cookies to measure our website activity.</div>
        <div className={cn('buttons')}>
          <button onClick={denyCookie} className={cn('button', 'declineButton')}>
            <span className={cn('label')}>Reject</span>
          </button>
          <button onClick={acceptCookie} className={cn('button', 'acceptButton')}>
            <span className={cn('label')}>Accept</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Cookiebar

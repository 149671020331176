// extracted by mini-css-extract-plugin
export var header = "Header-module--header--PTW6K";
export var hidden = "Header-module--hidden--K7VuU";
export var idle = "Header-module--idle--Al9mW";
export var colorTransition = "Header-module--colorTransition--kEFnA";
export var padding = "Header-module--padding--4xuic";
export var main = "Header-module--main--PNmxe";
export var logoLink = "Header-module--logoLink--QxIzz";
export var isOpen = "Header-module--isOpen--tHWyA";
export var toggleLabel = "Header-module--toggleLabel--P9Lrd";
export var labelInner = "Header-module--labelInner--6JExK";
export var showLabel = "Header-module--showLabel--Jbf+V";
export var logo = "Header-module--logo--LQlJi";
export var phrase = "Header-module--phrase--elPRw";
export var desktop = "Header-module--desktop--gm+Hp";
export var fadeInHeader = "Header-module--fadeInHeader--wU32p";
export var mobile = "Header-module--mobile--zuQxV";
export var fadeIn = "Header-module--fadeIn--Lzl2U";
export var toggle = "Header-module--toggle--IX8zh";
export var inside = "Header-module--inside--RAgPq";
export var icon = "Header-module--icon--qpYke";
export var menu = "Header-module--menu--320m1";
export var reset = "Header-module--reset--lqUSO";
export var toggleIcons = "Header-module--toggleIcons--FgR7n";
export var toggleIconsMenu = "Header-module--toggleIconsMenu--vo4QB";
export var transitionUp = "Header-module--transitionUp--36eyT";
export var transitionDown = "Header-module--transitionDown--58qSA";
export var inner = "Header-module--inner--OZyUp";
export var top = "Header-module--top--okB5X";
export var bottom = "Header-module--bottom--XpyNH";
export var text = "Header-module--text--E9p53";
export var headroomWrapper = "Header-module--headroomWrapper--zi+9L";
export var fallbackToggle = "Header-module--fallbackToggle--K57p7";